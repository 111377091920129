<template>
  <div v-if="myMBTI" class="container cover">
    <h1 class="main-top">Food Brand MBTI Test</h1>
    <h1 class="result-title" v-html="result[myMBTI].title"></h1>
    <img :src="`/image/${result[myMBTI].img}.png`" :alt="result[myMBTI].title" class="result" />
    <section class="hashtag">
      <span v-for="tag in result[myMBTI].hashtag.split(',')" :key="tag" class="tag">{{tag}}</span>
    </section>

    <section class="description" v-html="result[myMBTI].description"></section>
    <section class="features">
      <ul>
        <li v-for="f in result[myMBTI].features" :key="f">{{f}}</li>
      </ul>
    </section>
    <section class="features">
      <h2 class="sub-title">fbti를 한 사람 중 나와 같은 유형은?</h2>
      <div class="percent">{{percent}}%</div>
    </section>
    <div class="partner-container">
      <section class="features">
        <h2 class="sub-title">킹갓 케미</h2>
        <img
          :src="`/image/${result[myGoodPartner].img}.png`"
          :alt="result[myGoodPartner].title"
          class="result"
        />
        <div class="partner-title" v-html="result[myGoodPartner].title"></div>
      </section>
      <section class="features">
        <h2 class="sub-title">홀리 케미</h2>
        <img
          :src="`/image/${result[myBadPartner].img}.png`"
          :alt="result[myBadPartner].title"
          class="result"
        />
        <div class="partner-title" v-html="result[myBadPartner].title"></div>
      </section>
    </div>
    <h2 class="sub-title">이 결과 공유하기</h2>
    <div class="share-box">
      <a
        class="icon"
        target="_blank"
        onclick="window.open(this.href,'targetWindow',`toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, width=600px, height=800px `);return false;"
        :href="`https://www.facebook.com/sharer/sharer.php?u=https://fbti-ns.com/result/${myMBTI}`"
      >
        <picture>
          <img src="/icon/facebook.png" alt="페이스북 공유하기" />
        </picture>
      </a>
      <a
        class="icon"
        target="_blank"
        onclick="window.open(this.href,'targetWindow',`toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=600px,height=800px`); return false;"
        :href="`https://twitter.com/intent/tweet?url=https://fbti-ns.com/result/${myMBTI}`"
      >
        <picture>
          <img src="/icon/twitter.png" alt="트위터 공유하기" :style="{'border-radius' : '0px'}" />
        </picture>
      </a>
      <a class="icon" @click="kakao" id="kakao-link-btn">
        <picture>
          <img src="/icon/kakao.png" alt="카카오톡 공유하기" />
        </picture>
      </a>
      <a class="icon" @click.prevent="copyURL">
        <picture>
          <img src="/icon/link.png" alt="링크 공유하기" />
        </picture>
      </a>
    </div>
    <button class="cta" @click="goHome">
      <span>테스트 다시하기</span>
    </button>
  </div>
</template>
<script>
import copy from "copy-to-clipboard";
export default {
  name: "Result",
  computed: {
    myGoodPartner() {
      return this.result[this.myMBTI].good;
    },
    myBadPartner() {
      return this.result[this.myMBTI].bad;
    }
  },
  data() {
    return {
      myMBTI: null,
      result: {
        entj: {
          title: `화끈한 리더 <span class='title'>"신라면"</span>`,
          text: `화끈한 리더 "신라면" - 나와 어울리는 식품은?`,
          img: "신라면",
          description: `타고난 리더로 강한 의지와 추진력이 돋보이는 당신은
            화끈한 매운맛으로 대중의 입맛을 사로잡은
            라면계의 선두주자 신라면을 닮았네요!`,
          hashtag:
            "#타고난리더, #협상가, #인정욕구, #사업가기질, #사교적, #강단있음",
          features: [
            "의지력이 강한 유형!",
            "천성적으로 타고난 리더에요",
            "계획을 단계별로 실행해요",
            "감정 표현이 조금은 서툴러요",
            "우호적이고, 사교적이며 활력이 넘처요!",
            "체계적이고 단호한 성격이에요",
            "성취 가능한 도전에 매력을 느껴요~"
          ],
          good: "estp",
          bad: "esfj"
        },
        isfp: {
          title: `다정다감<span class='title'>"옥수수깡"</span>`,
          text: `다정다감 "옥수수깡" - 나와 어울리는 식품은?`,
          img: "옥수수깡",
          description: `조용하고 다정다감하며 반전 매력의 소유자인 당신은
            달콤 고소한 맛과 톡톡 터지는 식감으로 입맛을 사로잡는 
            ‘깡’의 신흥 강자 옥수수깡을 닮았네요!`,
          hashtag: "#싫은소리못함, #숨은인기인, #조용함, #감각적, #말싸움싫어",
          features: [
            "조용하고 느긋하면서도 끼가 많은 숨은 인기인이에요!",
            "그 누구보다도 낭만적이고 예술적인 끼를 갖고 있어요",
            "자신보다 다른 사람의 입장을 더 헤아리느라 참을 때가 더 많아요",
            "지루함을 피하고 매 순간 새로운 경험을 하는 것을 즐겨요~",
            "딱딱한 설명이나 반복적인 연습을 지겨워하고 몸을 움직이는 활동을 좋아해요",
            "주변 환경이 바뀌거나 예상치 못한 일이 생겨도 조바심을 내지 않아요",
            "말보다는 다정한 행동으로 사람들에게 따뜻함을 느끼게 해요"
          ],
          good: "infj",
          bad: "intp"
        },
        estj: {
          title: `철두철미<span class='title'>"엑설런스 다크"</span>`,
          text: `철두철미 "엑설런스 다크" - 나와 어울리는 식품은?`,
          img: "엑설런스",
          description: `현실적이며 솔직한 성격을 지닌 당신은 
            달콤 쌉싸름한 매력을 풍기는 
            초콜릿 엑설런스 다크와 닮았군요!`,
          hashtag: "#논리왕, #효율성갑, #철두철미, #객관적, #현재중심적",
          features: [
            "비효율적이거나 지나치게 긴 과정은 견디기 힘들어요",
            "미래보다는 현재에 관심이 많아요!",
            "검증되지 않은 아이디어는 받아들이기 좀 그래요.",
            "책임감이 강하고 말한 것은 꼭 지켜요",
            "분명한 직업 윤리를 가지며 효율적, 생산적, 현실적으로 일해요!",
            "객관적으로 결정 잘하는 사람, 나야 나!",
            "다른 사람에게 무관심하고 퉁명스럽다는 오해를 받기도 하지만 알고 보면 세상 따뜻한 사람!"
          ],
          good: "infp",
          bad: "infj"
        },
        esfj: {
          title: `상큼 청량<span class='title'>"카프리썬 오렌지"</span>`,
          text: `상큼 청량 "카프리썬 오렌지" - 나와 어울리는 식품은?`,
          img: "카프리썬",
          description: `사교적이면서 리액션이 풍부해 
            어딜가나 분위기 메이커인 당신은 
            상큼 청량한 카프리썬 오렌지를 닮았군요! `,
          hashtag:
            "#리액션부자, #친절친절, #평화와균형, #친선도모, #타인시선의식",
          features: [
            "리액션이 좋아서 강의를 들을 때 끄덕끄덕 반응 열심히 하는 사람, 나야 나!",
            "제안이나 부탁을 거절 당할까봐 걱정돼요",
            "타인에게 한없이 친절한데 한 번 상처 받으면 오래가요",
            "평화와 균형을 중요시해서 상대의 기분을 상하게 하지 않기 위해 조심! 또 조심해요",
            "즉흥 데이트보다는 계획적인 데이트를 좋아해요~",
            "리더십 하나는 끝내줘요 !",
            "준비성 철저한 사람 바로 나! 참을성 강한 사람 바로 나!"
          ],
          good: "istp",
          bad: "estj"
        },
        esfp: {
          title: `인기쟁이 <span class='title'>"츄파춥스"</span>`,
          text: `인기쟁이 "츄파춥스" - 나와 어울리는 식품은?`,
          img: "츄파춥스",
          description: `개성이 뚜렷하고 모험심이 강하며 
            인기쟁이인 당신은 다양한 색과 맛으로 
            많은 사람에게 사랑받는 츄파춥스를 닮았군요!`,
          hashtag:
            "#평화주의자, #열정파워, #분위기메이커, #적응력갑, #관종끼폭발",
          features: [
            "책보다는 실제 경험을 통해 배우는 것을 좋아해요.",
            "관종끼가 많고 주목받는 것을 좋아하며 오지랖이 넓어요!",
            "단점보다는 장점을 찾아내고, 다툼을 멀리하며 평화를 추구하는 나는야 평화주의자.",
            "사교적이며, 개방적이고 뭐든지 열정! 열정! 열정!",
            "어떤 상황이 벌어져도 적응 잘하는 사람 나야 나!",
            "계획적이고 규칙적인 일들 하는 거? 저랑 안 맞아요.",
            "독창성, 예술성, 창의성이 뛰어난 사람 바로 저에요."
          ],
          good: "intj",
          bad: "esfj"
        },
        enfj: {
          title: `말랑 스윗<span class='title'>"알쿠니아 황도"</span>`,
          text: `말랑 스윗 "알쿠니아 황도" - 나와 어울리는 식품은?`,
          img: "alkunia",
          description: `배려심 가득하고 타인의 의견을 
            경청하며 존중하는 당신은 부드럽고 스윗한
            알쿠니아 황도를 닮았군요!`,
          hashtag: "#책임감, #따뜻따뜻, #야망가, #로맨틱좋아, #배려왕",
          features: [
            "주변 시선에 민감하게 반응하며 책임감이 무척이나 강해요!",
            "마음이 여려서 거절을 어려워해요",
            "눈에 보이는 분명한 것이나 사실보다는 이면에 숨겨진 가능성에 관심이 많아요",
            "약속을 끊임없이 잡지만, 때론 혼자의 시간을 보내는 것도 좋아해요",
            "가치 있다고 생각하는 일에는 온 열정을 불태워 헌신해요",
            "타인의 의견을 중요하게 생각하며, 다른 사람을 우선으로 생각해주는 배려 왕이에요!",
            "공감 능력 짱! 표현력이 좋아요!"
          ],
          good: "istp",
          bad: "infj"
        },
        isfj: {
          title: `세상 진국<span class='title'>"쿡탐 고기곰탕"</span>`,
          text: `세상 진국 "쿡탐 고기곰탕" - 나와 어울리는 식품은?`,
          img: "고기곰탕",
          description: `진중하면서도 은근히 사교적인 당신은 
            진국인 육수와 고기 건더기가 낭낭하게 들어가 
            사람들의 입맛을 사로잡는 고기 곰탕을 닮았네요!`,
          hashtag:
            "#헌신적, #세심꼼꼼, #경험좋아, #감수성풍부, #사교적이면서내성적",
          features: [
            "세심하고 꼼꼼하면서 성실한 유형!",
            "내성적이기도 하지만 사교적이에요.",
            "기억력이 좋아요.",
            "사람들과의 마찰을 싫어해요!",
            "남을 돕거나 베푸는 걸 좋아해요",
            "다수보다는 소수의 친구와 깊은 우정을 나눠요!",
            "갑작스러운 변화는 조금 당황스러워요!"
          ],
          good: "intp",
          bad: "isfp"
        },
        istp: {
          title: `호기심 천국<span class='title'>"하우스 와사비"</span>`,
          text: `호기심 천국 "하우스 와사비" - 나와 어울리는 식품은? `,
          img: "하우스와사비",
          description: `호기심이 왕성하고 탐구심이 강한 당신은
            강력한 호기심을 유발하는 맛을 가진 
            하우스 와사비를 닮았네요!`,
          hashtag: "#이성적, #호기심, #만능재주꾼, #경험적, #단순복잡, #친절함",
          features: [
            "호기심이 매우 왕성하고 무언가를 만드는 것을 좋아해요!",
            "얼핏 보면 단순해 보이지만 꽤 복잡한 유형!",
            "자기만의 시간과 공간을 매주 중요하게 생각해요.",
            "타인에게 관심이 많지만, 그들의 감정을 파악하는 일은 조금 어려워요.",
            "새로운 것을 경험하는 일을 좋아해요.",
            "기계나 도구를 능숙하게 다뤄요.",
            "반복되는 일상은 너무 지루해요."
          ],
          good: "enfj",
          bad: "intj"
        },
        estp: {
          title: `행동 대장 <span class='title'>"웰치스"</span>`,
          text: `행동 대장 "웰치스" - 나와 어울리는 식품은?`,
          img: "웰치스",
          description: `직관력이 뛰어나고 직설적이며 나서는 것을 
            좋아하는 당신은 톡 쏘는 탄산과 스윗한 포도맛을 
            동시에 느낄 수 있는 웰치스 포도를 닮았네요!`,
          hashtag: "#명석함, #에너제틱, #분위기살리고, #직관력, #탐험대장",
          features: [
            "넘치는 에너지로 모험을 즐기는 유형",
            "타인의 작은 변화도 금방 파악하는 센스쟁이!",
            "틀에 얽매인 것을 못 참아요!",
            "운동에 소질이 있거나 스포츠를 사랑해요!",
            "적극적이고 적응력이 뛰어나요",
            "때로 행동이 너무 앞서기도 해요",
            "무거운 분위기 싫어요, 난 그냥 분위기 띄울래요"
          ],
          good: "entj",
          bad: "enfp"
        },
        infp: {
          title: `스윗 공감 <span class='title'>"멘토스"</span>`,
          text: `스윗 공감 "멘토스" - 나와 어울리는 식품은?`,
          img: "멘토스",
          description: `친한 친구와 있을 때 포텐이 터지는 당신은 
            평소엔 스윗하다가 탄산을 만났을 때 
            숨겨진 폭발력을 보여주는 멘토스를 닮았네요!`,
          hashtag: "#잡생각, #이해심, #자유추구, #공감력, #혼자놀기달인",
          features: [
            "친한 사람과 있을 때 폭발적인 개그 본능이 튀어나와요~",
            "은근히 웃긴 사람이 많은 유형",
            "연락하는 사람이 매번 비슷비슷해요",
            "사소한 디테일을 세심하게 잘 캐치해요!",
            "친구들이 평소와 살짝 다른 반응을 보이면 온갖 생각을 다해요",
            "잡생각이 제일 많은 유형이에요",
            "집콕의 달인! 와이파이와 먹을 것만 있다면 집에서 일주일도 가능~"
          ],
          good: "istj",
          bad: "istp"
        },
        infj: {
          title: `투명 청정 <span class='title'>"백산수"</span>`,
          text: `투명 청정 "백산수" - 나와 어울리는 식품은?`,
          img: "백산수",
          description: `통찰력 있고 공감 능력이 좋으며
            투명한 마음을 가진 성격으로 
            맑고 깨끗한 청정! 백산수와 닮았네요!`,
          hashtag: "#공감능력, #신중함, #불공평싫어, #직관력, #배려왕",
          features: [
            "공감 능력이 좋고 이해, 배려심이 깊어요.",
            "점잖고 온화하며, 정직하고 신중해요!",
            "뚜렷한 신념과 가치관이 있어요.",
            "사람 많은 걸 좋아하지 않아요.",
            "소외된 사람을 잘 챙겨주는 섬세함!",
            "화났을 때 상대방에게 상처를 주지 않기 위해 혼자 감정을 삭히려고 노력해요.",
            "걱정, 조심성이 많아요."
          ],
          good: "esfp",
          bad: "entj"
        },
        intj: {
          title: `완벽 주의 <span class='title'>"파워오투"</span>`,
          text: `완벽 주의 "파워오투" - 나와 어울리는 식품은?`,
          img: "파워오투",
          description: `실수를 용납하지 않고 완벽을 추구하며
            본인만의 기준이 높은 성격으로 
            특별한 산소 음료인 파워오투와 닮았네요!`,
          hashtag: "#츤데레, #결단력, #완벽함, #논리적, #합리적",
          features: [
            "개인적인 감정으로 함부로 사람을 판단하지 않아요!",
            "사소한 배려에도 감동받아요",
            "혼자가 제일 편해요!",
            "열 가지가 맞고 하나만 달라도 다르다고 생각해요",
            "자신만의 확고한 기준에 있어요",
            "계획 짜는 걸 즐겨요~",
            "의지 결단력이 강해요!"
          ],
          good: "estp",
          bad: "esfj"
        },
        entp: {
          title: `자존감 왕 <span class='title'>"프링글스"</span>`,
          text: `자존감 왕 "프링글스" - 나와 어울리는 식품은?`,
          img: "프링글스",
          description: `새로운 시도나 발명을 좋아하고 
            자존감이 높으며 가치관이 뚜렷한 모습이 
            다른 과자와는 다른 독특한 포장으로 
            많은 이들에게 사랑받는 프링글스와 닮았군요!`,
          hashtag:
            "#새로움, #럭비공, #분석력, #특이한안목, #모아니면도, #자아도취",
          features: [
            "럽마셀프! 자기 자신이 가장 멋지다고 생각해요!",
            "분석을 좋아해서 MBTI를 좋아해요",
            "어떤 말싸움이든 이길 자신 있어요",
            "이 유형이 시비를 건다면 당신을 사랑하는 것!",
            "내 말을 들어주지 않으면 화가 나요",
            "호기심이 많고, 다른 사람들과 다른 새로운 시각으로 세상을 봐요",
            "모 아니면 도! 극단적이에요"
          ],
          good: "estj",
          bad: "enfj"
        },
        istj: {
          title: `존재감 확실 <span class='title'>"통후추"</span>`,
          text: `존재감 확실 "통후추" - 나와 어울리는 식품은?`,
          img: "통후추",
          description: `조용하고 강한 독립심을 지닌 당신은 
            음식에 들어가는 양에 비해 존재감이 확실한 
            통후추를 닮았군요!`,
          hashtag:
            "#인내력 짱, #계획표, #약속 잘 지킴, #걸어 다니는 시계, #혹시모범생?",
          features: [
            "혼자서도 척척 자기 일을 잘하고 믿음직스러워요!",
            "친구를 사귀면 깊은 우정을 나누고 관계를 오래 유지해요~",
            "자신의 생각이 분명해서 그 생각을 바꾸지 못하기도 해요",
            "규칙이나 약속 등을 잘 지키고 적응하는 데에 어려워하지 않아요!",
            "책임감 있고 성실하며 모범적인 모습을 자주 보여요!",
            "감정 표현이 다소 서툴러서 다른 사람들로부터 차갑다는 이야기를 듣곤 해요",
            "무엇을 할 때 상세한 설명을 해 주지 않으면 막막하고 불편해요"
          ],
          good: "esfj",
          bad: "estp"
        },
        intp: {
          title: `차분한 지성인 <span class='title'>"빌리 블랑카 오일"</span>`,
          text: `차분한 지성인 "빌리 블랑카 오일" - 나와 어울리는 식품은?`,
          img: "빌라블랑카오일",
          description: `좋아하는 것에 대해 깊게 탐구하는 당신은 
            오랜 역사를 통해 쌓은 노하우로 최고의 자리를 유지하고 있는 
            오일계의 명품, 빌라 블랑카 오일을 닮았군요.`,
          hashtag: "#스마트, #팩폭러, #호불호확실, #논리끝판왕, #독서왕",
          features: [
            "좋아하는 것과 싫어하는 것이 분명하고 혼자 있는 것을 즐겨요!",
            "관심 있는 주제와 관련된 책을 읽고 매체를 활용하면서, 백과사전 수준의 지식을 쌓아요",
            "분석과 추리, 논리적 말하기를 좋아해서 주변인에게 차갑거나 진지한 인상을 줄 수 있어요",
            "일상적이거나 가벼운 잡담에 크게 관심이 없어요~",
            "이해가 빠르고 대상을 파악하는 통찰력이 있어요",
            "사람에 대한 관심이 적고 감정적 공감을 어려워해요",
            "지적 호기심과 상상력의 불씨가 언제든지 타오를 준비가 되어 있어요!"
          ],
          good: "enfj",
          bad: "infp"
        },
        enfp: {
          title: `천진난만 <span class='title'>"혼다시"</span>`,
          text: `천진난만 "혼다시" - 나와 어울리는 식품은?`,
          img: "hondashi",
          description: `천진난만하며 정이 많아 주변인과 잘 어울리는 당신은 
            어떤 요리와도 조화를 이루는 혼다시를 닮았군요!`,
          hashtag:
            "#호기심대마왕, #급발진, #순수결정체, #느낌가는대로, #창의적",
          features: [
            "창의적인 눈으로 세상을 바라보며, 열정적이에요!",
            "자신의 입장을 상대방이 먼저 알아주기를 바라다가 마음이 상하는 경우가 종종 있어요.",
            "사람들의 반응에 민감해서 감정 기복을 보이기도 하지만 기분이 풀리면 쿨하게 사과해요",
            "규칙이나 해야 할 일을 챙기기를 어려워해요",
            "무엇을 할 때 ‘느낌이 온다’ 싶으면 용감하게 행동해요!",
            "복잡하고 어려운 문제를 해결해 나갈 때 독창성을 발휘하는 경우가 많아요",
            "순수하고 천진난만하며 관심 있는 일은 척척 해내요!"
          ],
          good: "esfj",
          bad: "isfj"
        }
      },
      percent: 0
    };
  },
  metaInfo() {
    return {
      title: this.result[this.myMBTI].text,
      description: this.result[this.myMBTI].description,
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: this.result[this.myMBTI].text
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: `https://fbti-ns.com/image/${
            this.result[this.myMBTI].img
          }.png`
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.result[this.myMBTI].description
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `https://fbti-ns.com/result/${this.myMBTI}`
        }
      ]
    };
  },
  created() {
    this.myMBTI = this.$route.params.id;
    if (this.result[this.myMBTI] === undefined) {
      this.$router.push({
        name: "Home"
      });
    }
  },
  mounted() {
    document.body.style.backgroundColor = "#ffffff";
    const docRef = this.$firestore.collection("user").doc("fbti");
    docRef.get().then(doc => {
      const total = doc.data();
      this.percent = Number(
        ((total[this.myMBTI] ? total[this.myMBTI] : 0) / total.finishCount) *
          100
      ).toFixed(2);
    });
  },
  methods: {
    goHome() {
      this.$router.push({
        name: "Home"
      });
    },
    goResult(type) {
      this.$router.push({
        name: "Result",
        params: { id: type }
      });
      this.showAllTypes = false;
      this.myMBTI = type;
      window.scrollTo(0, 0);
    },
    moveToRecipe() {
      window.location.href = this.result[this.myMBTI].link;
    },
    kakao() {
      Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: this.result[this.myMBTI].text,
          description: this.result[this.myMBTI].description,
          imageUrl: `https://fbti-ns.com/image/${
            this.result[this.myMBTI].img
          }.png`,
          link: {
            mobileWebUrl: `https://fbti-ns.com/result/${this.myMBTI}`,
            webUrl: `https://fbti-ns.com/result/${this.myMBTI}`
          }
        },
        buttons: [
          {
            title: "테스트 시작!",
            link: {
              mobileWebUrl: `https://fbti-ns.com/result/${this.myMBTI}`,
              webUrl: `https://fbti-ns.com/result/${this.myMBTI}`
            }
          }
        ]
      });
    },
    copyURL() {
      copy(`https://fbti-ns.com/result/${this.myMBTI}`);
      window.alert("주소를 복사했습니다.");
    }
  }
};
</script>